//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getDateformat } from '@/util/getDate';
export default {
  data() {
    return {
      info: {},
      refundInfo: {},
    };
  },
  created() {
    this.getInfo();
  },
  methods: {
    getDateformat(val) {
      return getDateformat(val);
    },
    getInfo() {
      this.$axios
        .post(this.$api.samecity.service.orderRefundInfo, {
          id: this.$route.query.id,
        })
        .then(res => {
          if (res.code == 0) {
            this.info = res.result.info.order_info;
            this.refundInfo = res.result.info;
          }
        });
    },
  },
};
